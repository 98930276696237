<template>
    <button type="button" :class="actionClass" :style="actionStyle" :disabled="disabled"
            v-on:click.stop="clickButtonAction">
        <font-awesome-icon :icon="action.on.icon" size="sm" :class="{'mr-2':!action.compact}" v-if="action.on.icon && isToggled"/>
        <font-awesome-icon :icon="action.off.icon" size="sm" :class="{'mr-2':!action.compact}" v-if="action.off.icon && !isToggled"/>
        <template v-if="!action.compact">{{ actionLabel }}</template>
    </button>
</template>

<script>
export default {
    name: "ToggleAction",
    props: {
        action: {type: Object, default: null},
        disabled: {type: Boolean, default: false},
        forwardEvents: {type: Boolean, default: false},
        toggled: {type: Boolean, default: null},
    },
    data() {
        return {
            isToggled: this.toggled === null ? ('toggled' in this.action ? this.action.toggled : false) : this.toggled,
        }
    },
    computed: {
        actionClass: function () {
            if (this.isToggled) {
                return 'btn btn-sm ' + (this.action.on.class ? this.action.on.class : this.action.class);
            } else {
                return 'btn btn-sm ' + (this.action.off.class ? this.action.off.class : this.action.class);
            }
        },
        actionLabel: function () {
            if (this.isToggled) {
                return this.action.on.label ? this.action.on.label : this.action.label;
            } else {
                return this.action.off.label ? this.action.off.label : this.action.label;
            }
        },
        actionStyle: function () {
            if (this.isToggled) {
                return this.action.on.style ? this.action.on.style : this.action.style;
            } else {
                return this.action.off.style ? this.action.off.style : this.action.style;
            }
        },
        actionToggled: function () {
            return this.toggled === null ? ('toggled' in this.action ? this.action.toggled : false) : this.toggled;
        }
    },
    methods: {
        clickButtonAction() {
            this.isToggled = !this.isToggled;
            this.action.toggled = this.isToggled;
            let event = this.action.event ? this.action.event : this.action.name;
            if (this.forwardEvents) {
                this.$emit('forward-event', {
                    event: event,
                    data: this.action,
                });
            } else {
                this.$emit(event, this.action);
            }
        },
    }
}
</script>

<style scoped>
</style>
